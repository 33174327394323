import { LoadingButton } from "@mui/lab";
import Grid from "@mui/material/Grid";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Field, Form, Formik } from "formik";
import { useCallback, useState } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import * as Yup from "yup";
import api from "../api";
import "../App.scss";
import { removeLayoutType, setIsNewLogin, setJWT } from "../utils/cookies";
import { endpoints } from "../utils/endpoints";
import { redirectToTarget } from "../utils/redirectToTarget";
import ExpiredPasswordModal from "./ExpiredPasswordModal";
import ForgotPassword from "./ForgotPassword";
import TextField from "./TextField";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Hibás e-mail formátum")
    .required("Kötelező megadni"),
  password: Yup.string().required("Kötelező megadni"),
});

const LoginForm = ({
  setError,
  check,
}: {
  setError: (value: string) => void;
  check: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [expiredPasswordModalOpen, setExpiredPwModalOpen] =
    useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSendPasswordResetFromAdminEmail = useCallback((email: string) => {
    axios
      .post(endpoints.forgottenPassword, {
        email,
      })
      .then(() => setExpiredPwModalOpen(true))
      .catch(() => setError("Hiba a feldolgozás során"));
  }, [setError]);

  const handleReCaptchaVerify = useCallback(async (form: {email: string; password: string;}) => {
    let token = null;
    
    if (process.env.REACT_APP_RECAPTCHA_SITE_KEY && executeRecaptcha) {
      token = await executeRecaptcha('login');
      if (!token) {
        setError("ReCaptcha hiba");
        return;
      }
    }

    api
      .post(endpoints.login, { ...form, "g-recaptcha-response": token })
      .then((response: AxiosResponse) => {
        removeLayoutType();
        setJWT(response.data.token || "");
        setIsNewLogin();
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.status === "DISABLED") {
          setError(
            "A megadott felhasználó számára nem elérhető a rendszer."
          );
        } else if (error.response?.data.status === "CAPTCHA ERROR") {
          setError("Captcha azonosítás sikertelen.");
        } else if (error.response?.data.message) {
          handleSendPasswordResetFromAdminEmail(form.email);
        } else {
          setError(
            error.response?.status === 401
              ? "Hibás bejelentkezési adatok."
              : "Hiba történt."
          );
        }
      })
      .finally(() => {
        setLoading(false);
        redirectToTarget(null, setLoading, check);
      });

  }, [check, executeRecaptcha, handleSendPasswordResetFromAdminEmail, setError]);

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={handleReCaptchaVerify}
      validationSchema={LoginSchema}
    >
      {({ errors, touched, values }) => (
        <Form style={{ width: "100%" }}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Field as="div" field="email">
                <TextField
                  required
                  name="email"
                  label="E-mail cím"
                  margin="normal"
                  error={!!(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                  autoFocus
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field as="div" field="password">
                <TextField
                  required
                  name="password"
                  type="password"
                  label="Jelszó"
                  margin="normal"
                  error={!!(errors.password && touched.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <ForgotPassword setError={setError} formEmail={values.email} />
            </Grid>
            {expiredPasswordModalOpen && (
              <ExpiredPasswordModal
                isOpen={expiredPasswordModalOpen}
                onClose={() => setExpiredPwModalOpen(false)}
              ></ExpiredPasswordModal>
            )}
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                color="secondary"
                variant="contained"
                size="large"
                type="submit"
                disabled={
                  !!(errors.email || errors.password) ||
                  !(values.email && values.password)
                }
                sx={{
                  padding: "8px",
                  height: 56,
                }}
                loading={loading}
              >
                Bejelentkezés
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const Login = ({
  setError,
  check,
}: {
  setError: (value: string) => void;
  check: () => void;
}) => {
  if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
    return <LoginForm setError={setError} check={check} />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      language="hu"
      scriptProps={{
        async: true, // Load script asynchronously
        defer: true, // Defer script loading
        appendTo: 'head', // Append script to the document head
        nonce: undefined,
      }}
    >
      <LoginForm setError={setError} check={check} />
    </GoogleReCaptchaProvider>
  );
};

export default Login;
